var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-detail-container"},[(_vm.project)?_c('div',{staticClass:"project-detail-wrapper"},[_c('div',{staticClass:"fixed-info-bar"},[_c('div',{ref:"bts",staticClass:"info-direction-bts scrollObj"},[(_vm.prev)?_c('router-link',{staticClass:"bt__item",attrs:{"tag":"div","to":{
            path: ("/project/" + (_vm.prev.id))
          }}},[_vm._v("previous project")]):_vm._e(),(_vm.next)?_c('router-link',{staticClass:"bt__item",attrs:{"tag":"div","to":{
            path: ("/project/" + (_vm.next.id))
          }}},[_vm._v("next project")]):_vm._e()],1),(_vm.info)?_c('div',{ref:"bottominfo",staticClass:"info-list-container scrollObj",style:({
          bottom: (_vm.offsetBottom + "px")
        }),attrs:{"data-callback":"1"}},_vm._l((_vm.info),function(item,index){return _c('div',{key:index,staticClass:"info__item"},[_c('p',{staticClass:"item__title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"item__content"},[_vm._v(_vm._s(item.content))])])}),0):_vm._e(),(_vm.recommends)?_c('div',{ref:"totop",staticClass:"info-to-top",on:{"click":_vm.totop}},[_vm._v(" back top ")]):_vm._e()]),_c('div',{ref:"p-container",staticClass:"project-detail-main-body"},[_c('div',{staticClass:"pd-main-container"},[_c('div',{staticClass:"project__title"},[_c('p',{staticClass:"scrollObj"},[_vm._v(_vm._s(_vm.project.name.cn))]),_c('p',{staticClass:"scrollObj"},[_vm._v(_vm._s(_vm.project.name.en))])]),_c('div',{staticClass:"project__main-content"},[(_vm.contents)?_vm._l((_vm.contents),function(content,index){return _c('div',{key:index,staticClass:"content__item scrollObj",class:content.type,domProps:{"innerHTML":_vm._s(_vm.renderHTML(content))}})}):_vm._e()],2)])]),(_vm.$isMobile && _vm.info)?[_c('div',{staticClass:"project-params-container"},_vm._l((_vm.info),function(item,index){return _c('div',{key:index,staticClass:"params__item"},[_c('p',{staticClass:"item__title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"item__content"},[_vm._v(_vm._s(item.content))])])}),0)]:_vm._e(),(_vm.recommends)?_c('div',{staticClass:"project-recommend-container"},[_c('div',{staticClass:"title scrollObj"},[_vm._v("Recommend")]),_c('div',{staticClass:"recommend-box"},[_c('div',{staticClass:"recommend-box__wrapper"},_vm._l((_vm.recommends),function(recommend){return _c('a',{key:recommend.id,staticClass:"box__item scrollObj",class:{
              hoverEffect: !_vm.$isMobile
            },attrs:{"href":recommend.link ? recommend.link : 'javascript:void(0)',"target":"_blank"}},[_c('div',{staticClass:"box__inner"},[_c('div',{staticClass:"inner__body"},[_c('div',{staticClass:"good-cover",style:({
                    backgroundImage: ("url(" + (recommend.cover) + ")")
                  })}),_c('div',{staticClass:"mask",style:({
                    backgroundColor: ("" + (recommend.bg))
                  })})])])])}),0)])]):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }