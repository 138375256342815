<template>
  <div class="project-detail-container">
    <div class="project-detail-wrapper" v-if="project">
      <div class="fixed-info-bar">
        <div class="info-direction-bts scrollObj" ref="bts">
          <router-link
            tag="div"
            class="bt__item"
            v-if="prev"
            :to="{
              path: `/project/${prev.id}`
            }"
            >previous project</router-link
          >
          <router-link
            class="bt__item"
            tag="div"
            v-if="next"
            :to="{
              path: `/project/${next.id}`
            }"
            >next project</router-link
          >
        </div>
        <div
          class="info-list-container scrollObj"
          data-callback="1"
          v-if="info"
          ref="bottominfo"
          :style="{
            bottom: `${offsetBottom}px`
          }"
        >
          <div class="info__item" v-for="(item, index) in info" :key="index">
            <p class="item__title">{{ item.title }}</p>
            <p class="item__content">{{ item.content }}</p>
          </div>
        </div>
        <div ref="totop" class="info-to-top" v-if="recommends" @click="totop">
          back top
        </div>
      </div>
      <div class="project-detail-main-body" ref="p-container">
        <div class="pd-main-container">
          <div class="project__title">
            <p class="scrollObj">{{ project.name.cn }}</p>
            <p class="scrollObj">{{ project.name.en }}</p>
          </div>
          <div class="project__main-content">
            <template v-if="contents">
              <div
                class="content__item scrollObj"
                :class="content.type"
                v-for="(content, index) in contents"
                :key="index"
                v-html="renderHTML(content)"
              ></div>
            </template>
          </div>
        </div>
      </div>
      <template v-if="$isMobile && info">
        <div class="project-params-container">
          <div class="params__item" v-for="(item, index) in info" :key="index">
            <p class="item__title">{{ item.title }}</p>
            <p class="item__content">{{ item.content }}</p>
          </div>
        </div>
      </template>
      <div class="project-recommend-container" v-if="recommends">
        <div class="title scrollObj">Recommend</div>
        <div class="recommend-box">
          <div class="recommend-box__wrapper">
            <a
              class="box__item scrollObj"
              :class="{
                hoverEffect: !$isMobile
              }"
              v-for="recommend in recommends"
              :key="recommend.id"
              :href="recommend.link ? recommend.link : 'javascript:void(0)'"
              target="_blank"
            >
              <div class="box__inner">
                <div class="inner__body">
                  <div
                    class="good-cover"
                    :style="{
                      backgroundImage: `url(${recommend.cover})`
                    }"
                  ></div>
                  <div
                    class="mask"
                    :style="{
                      backgroundColor: `${recommend.bg}`
                    }"
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { imgPreload } from "@/assets/utils/common";
const defaultBottom = 100;
export default {
  name: "project-detail-new",
  props: ["id"],
  data() {
    return {
      project: null,
      info: null,
      contents: null,
      next: null,
      prev: null,
      recommends: null,
      offsetBottom: defaultBottom
    };
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  created() {},
  updated() {
    imgPreload().then(() => {
      this.PAGE_LOADED();
      this.$nextTick(function() {
        window.addEventListener("scroll", this.scrollComputed);
      });
    });
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      this.PAGE_LOADING();
      this.getDetail().then(res => {
        const { project, info, next, prev, recommend } = res.data;
        this.project = project;
        this.info = info;
        this.contents = this.project.contents;
        this.next = next;
        this.prev = prev;
        this.recommends = recommend;
      });
    },
    getDetail() {
      return this.$request.get("/api/product/detail", {
        id: this.id
      });
    },
    renderHTML(content) {
      const type = content.type;
      let html;
      if (type === "img") {
        html = `<img data-src="${content.value}" class="preload"  />`;
      } else if (type === "textarea") {
        html = content.value;
      } else if (type === "video") {
        html = `<video src="${content.value}"></video>`;
      }
      return html;
    },
    scrollComputed() {
      const screenHeight = window.innerHeight;
      // const pageHeight = document.body.clientHeight;
      const dom = this.$refs["p-container"];
      const bcr = dom.getBoundingClientRect();
      const { bottom } = bcr;
      if (bottom <= screenHeight && screenHeight - bottom >= 100) {
        if (!this.$refs.bts.classList.contains("hidden")) {
          this.$refs.bts.classList.add("hidden");
        }
        this.offsetBottom = screenHeight - bottom;
      } else {
        this.offsetBottom = defaultBottom;
        this.$refs.bts.classList.remove("hidden");
      }
      if (this.$refs.totop) {
        if (bottom <= screenHeight && screenHeight - bottom >= 200) {
          this.$refs.totop.classList.add("show");
        } else {
          this.$refs.totop.classList.remove("show");
        }
      }
    },
    totop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style></style>
